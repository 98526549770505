<template>
  <div class="login">  
    <top></top>
    <div class="center">
    <div class="Ulogin"> 
       <form action="">
       <h2>创建账号</h2>
        <div class="input">
       <input type="number" placeholder="请输入手机号码" v-model="phone">
       <el-button class="span" :disabled="disabled" @click="geet"> {{isRun?`${runTime}s`:"获取验证码"}}</el-button>
      <div class="steredcode">
          <input type="text" placeholder="验证码" v-model="sendregisteredcode">
         <input type="password" placeholder="设置8-18位字母、数字密码" v-model="password">
      </div>
        </div>
        <div class="agree">
         <el-checkbox v-model="checked"  @click='sure' :checked="checked">表示您已同意</el-checkbox><a @click="platformServices" >平台服务协议 </a><span>及</span><a @click="privacy">隐私政策</a>
        </div>
         <Goodsure :addDialog="addDialog" @del="del" ref="child" @addsure="addsure"></Goodsure>
        <div class="button" @click="register" >注册</div>

       </form>
  </div>
        
    </div>
    <Foo></Foo>
  </div>
</template>
<script>
import Top from "@/components/login/Top.vue";
import Foo from "@/components/login/Footer.vue";
import loginApi from '../api/loginApi';
import { Message } from "element-ui";
import Goodsure from "../components/center/entre/goodsurelog.vue";
// import axios from "axios"
export default {
  data(){
   return{
        addDialog: false,
      phone:"",
      sendregisteredcode:"",
      password:"",
      isRun:false,
      runTime:60,
       checked:true,
        disabled:false
   };
   
  },
  components: {
    Top,
    Foo,
    Goodsure
  },
  methods:{
     del(bool) {
      this.addDialog = bool;
    },
     addsure(bool) {
      this.addDialog = bool;
    },
    // 平台服务协议
    platformServices(){
       this.addDialog = true;
        this.$store.commit("intagreement",'platformServices')
         this.$refs.child.getAgreement()
    },
    // 隐私协议
    privacy(){
        this.addDialog = true;
   this.$store.commit("intagreement",'privacy')
    this.$refs.child.getAgreement()
    },
  geet(){
 if(!/^1\d{10}$/.test(this.phone)){
         Message.error('手机号格式不正确');
          this.phone=""
         return
       
      }
   //注册验证码
    loginApi.getRegisterCode(this.phone,res=>{
       if(res.data.code==200){
             Message({
          message: '发送成功',
          type: 'success'
        });
         this.isRun = true;
            this.disabled = true;
      this.autoTime = setInterval(() => {
        if(this.runTime===0){
          this.runTime=60;
          this.isRun = false;
             this.disabled = false;
          clearInterval(this.autoTime)
          return
        }
        this.runTime--
      }, 1000);
      }
      else{
 Message.error(res.data.message);
      }
    })
        
      
},
 sure(){
     this.checked=!this.checked
     
    },
register(){
   
   if(!/^1\d{10}$/.test(this.phone)){
         Message.error('手机号格式不正确');
         return 
      }
      if(!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,18}$/.test(this.password)){
     Message.error('密码格式不正确');
     return 
      } 
       const that = this
    loginApi.addUser({phone:this.phone,code:this.sendregisteredcode,password:this.password},function(res){
      if(res.data.code==200){
           Message.success('注册成功');
           that.$router.push("/login")
      }
      else{
          Message.error(res.data.message);
      }
     
    })

}
  }
};
</script>
<style lang="scss" scoped>
.steredcode{
  margin-top: -40px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none; 
    margin: 0; 
}
.center{
    width: 100%;
    height: 600px;
    /* border: 1px solid red; */
}
.Ulogin{
  width: 80%;
  height: 660px;
    /* border: 1px solid red; */
    margin-left: 340px;
    margin-top: 100px;
}
.code{
  display: flex;
  justify-content: space-between;
  width: 176px;
  height: 20px;
}
.code p{
 cursor: pointer;
   width: 170px;
  height: 20px;
  }
  
  .code p:nth-child(2){
    margin-left: 5px;
  }
  .code .active{
    color: red;

    }
    .input{
      
      margin-top: 30px;
    
  input{
      display: block;
      width: 280px;
      height: 40px !important;
      margin-top: 20px;
      border-radius: 3px;
      padding-left: 20px;
      outline: none;
      border: #ccc 1px solid;
    }
    }
  
    .agree{
     cursor: pointer;
      position: relative;
    top: 20px;
    /* padding-bottom: 30px; */
      font-size: 14px;
    
    }
    .agree a{
      color: rgb(64,158,255);
      margin-left: 2px;
      border-bottom: 1px solid rgb(79, 144, 218);
    }
    .agree span{
      color: rgb(64,158,255);
      margin-left: 2px;
     
    }
    .button{
        width: 300px;
      height: 36px;
      margin-top: 40px;
      background-color: rgb(233,79,85);
      border: 0;
      border-radius: 5px;
      color: white;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
    }
  .span{
      /* border: 1px solid red; */
      font-size: 14px;
      position: relative;
      top: -40px;
      left: 205px;
      color: rgb(133, 133, 133);
      cursor: pointer;
      width: 95px;
       background: white;
        border: 0;
      outline: 0;

  }
</style>